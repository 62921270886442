import React from 'react';

import { Wrapper } from './style';

const BlankContainer = ({children}) => {
    return(
        <Wrapper className=''>
            {children}
        </Wrapper>
    )
}

export default BlankContainer;