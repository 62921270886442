import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 20px 80px;
    /* margin: 10px 30px; */
    background: linear-gradient(225deg, #26223a, #30283a);
    

    @media(max-width: 540px){
        padding: 10px 20px;
    }
    /* .head-img{
        width: 100px;
        height: 100px;
    } */
    .padd-cont{
        padding-left: 15px;
    }
    .disp-flex{
        display: flex;

        .flex-50{
            flex: 0 0 49%;
            max-width: 49%;

            @media(max-width: 780px){
                flex: 0 0 99%;
                max-width: 99%;
            }
        }
    }
    .wrap{
        flex-wrap: wrap;
    }
    .main-container{
        margin-top: 20px;

        @media(max-width: 540px){
            margin-top: 10px;
        }
    }
`;