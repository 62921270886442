import styled from "styled-components";

export const OuterWrapper = styled.div`

`;

export const Wrapper = styled.div`
    
    a{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: black;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        text-decoration: none;

        .img-header{
            margin-right: 10px;
            margin-left: 10px;
        
        }
        img{
            width: 50px;
            height: 50px;

            @media (max-width: 780px){
                width: 30px;
                height: 30px;
            }
        }
        .text-cont{
            padding-right: 20px;
        }
        span{
            color: #eee;
        }
    }
    
`;