import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, Content } from "./style";

const Footer = ({children, style}) => {
    return(
        <Wrapper style={style}>
            <Content>
                {children}
                <div className="flex-100" style={{marginTop:'20px'}}>
                    <div className="disp-block padd-4px align-center" style={{borderTop:'1px dotted var(--appTextSecColor)'}}>
                        <span className="app-opp-text-color font-small fw-500">Copyright <sup>&copy;</sup> All Rights Reserved 2024</span>
                        <div className="disp-block padd-4px">
                            <Link to="/" className="app-opp-text-color font-very-small fw-600" style={{textDecoration:'none'}}>VOSKOOL TECHNOLOGIES LTD</Link>
                        </div>
                    </div>
                </div>
            </Content>
        </Wrapper>
    )
}

export default Footer;