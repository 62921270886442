import styled from "styled-components";


export const OuterWrapper = styled.div`
    margin: 0 15px;
    margin-bottom: 10px;
`;

export const Wrapper = styled.button`
    padding: 14px 40px;
    background: linear-gradient(90deg, #025ce3, #4e94fd);
    border-radius: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: .4s ease;
    display: flex;
    justify-content: stretch;
    align-items: center;
    &:hover{
        transform: scale(1.1);
    }

    a.cont-btn{
        color: #000;
        text-decoration: none;
    }
    
`;