import React from "react";

import { OuterWrapper, Wrapper } from "./style";
import { Link } from "react-router-dom";

const CustomPlayButton = ({name="", imgLogo}) => {
    return(
        <OuterWrapper>
            <Wrapper>
                <Link>
                    <div className="disp-block img-header">
                        <img src={imgLogo} alt={name} />
                    </div>
                    <div className="disp-block text-cont">
                        <span className="font-very-small">Get it on</span>
                        <div className="disp-block">
                            <span className="font-med">{name}</span>
                        </div>
                    </div>
                </Link>
            </Wrapper>
            <div className="disp-block align-center">
                <span className="font-very-small red">Coming soon! :)</span>
            </div>
        </OuterWrapper>
        
        
    )
}

export default CustomPlayButton;