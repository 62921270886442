import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 60px;
    width: 100%;
    background: var(--appOppTheme);
    border-radius: 20px;
`;

export const Content = styled.div`
    display: flex;
    -webkit-display: flex;
    -moz-display: flexbox;
    -ms-display: flex;
    flex-wrap : wrap;
    padding: 30px 10px;
    border-radius: 10px;
    @media (max-width: 375px) {
        padding: 20px 10px;
    }
`;