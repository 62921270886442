import React, {useState, useEffect, useRef, Suspense} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { GlobalStyle, SchoolAppStyle } from 'GlobalStyle';

import { motion } from 'framer-motion';
import clamp from 'lodash/clamp';
import range from 'lodash/range';

import Loader from 'components/app/loader';
// import CountDownTimer from 'components/app/countDownTimer';
// import LoginPage from "components/loginPage";
// import SignUpPage from 'components/signUpPage';
// import ActivateUserPage from 'components/activateUserPage';
import TermsOfService from 'components/voskoolTermOfService';
import PrivacyPolicy from 'components/voskoolTermOfService/privacyPolicy';
// import PasswordResetPage from 'components/passwordResetPage';
// import PasswordResetConfirmationPage from 'components/passwordResetPage/confirmationPage';
// import CreateSchoolAccount from 'components/createSchoolAccount';
// import Footer from "components/app/footer";

// import { LAUNCH_DATE } from 'actions/url';

import vomaLogo from 'images/voma_logo.png';
import nigerianFlagLogo from 'images/nigerian_flag.png';
import vomaFeatureImg from 'images/voma_web_feature.png';
import playStoreLogo from 'images/google_play_logo.png';
import vomaPlayGoQuizPhone from 'images/phone_voma_play_go_quiz.png';
import vomaJoinTorunamentPhone from 'images/phone_voma_tournament.png';
import vomaDownloadPhone from 'images/voma_download.gif';
import BlankContainer from 'components/smaller/blankContainer';
import CustomButton from 'components/smaller/customButton';
import CustomPlayButton from 'components/smaller/customPlayButton';
import Note from 'components/app/NOTE';

import './style.css';
import Footer from 'components/smaller/footer';

// import { phoneDivider } from 'actions/phone';
// const GlobalStyle = lazy(() => import('exportGlobalStyle'));
// const Layout = lazy(() => import('components/app/vo/layout'));
// const NavBar = lazy(() => import('components/navbar'));
// const Home = lazy(() => import('containers/home'));
// const Schools = lazy(() => import('containers/schools'));
// const SchoolOffers = lazy(() => import('containers/schoolOffers'));
// const StudentOffers = lazy(() => import('containers/studentOffers'));
// const ParentOffers = lazy(() => import('containers/parentOffers'));
// const Chunk = lazy(() => import('components/chunk'));

const IndexComponent = ({user={}}) => {
    const [navigate, setNavigate] = useState('');

    const constraintsRef = useRef(null);
    const [active, setActive] = useState(0);
    // const x = useMotionValue(0)
    // const drag = useMotionValue(0)

  

    // const [searchParam] = useSearchParams();
    const nav = useNavigate();

    const width = constraintsRef.current && constraintsRef.current.offsetWidth || 350
    useEffect(() => {
        if(navigate){
            nav(navigate);
            setNavigate('');
        }
    }, [navigate, nav])

    const Dots = ({ count, active }) => (
        <div className="dot-container">
          {range(count).map(i => (
            <motion.div
              className="dot"
              initial={false}
              animate={{
                scale: active === i ? 1.5 : 1,
                opacity: active === i ? 1 : 0.5,
              }}
            />
          ))}
        </div>
      )
      const Slide = ({ color, step, name, imgSrc, imgAltName, ...rest }) => (
        <div
          style={{ backgroundColor: color }}
          className="slide"
          {...rest}
        >
            <div className="disp-block align-center" style={{marginBottom:'10px'}}>
                <span className="font-med fw-600" style={{color:'#eee'}}><span className='font-big'>Step {step}:</span> {name}</span>
            </div>
            <div className='disp-block align-center'>
                <img src={imgSrc} alt={imgAltName} style={{maxWidth:'100%', maxHeight:'100vh'}} />
            </div>
        </div>
      )
      const slidesData = [{step:1, name:'Download and Install the VoMa Edutainment App', imgSrc:vomaDownloadPhone, imgAltName:'Voma Download'}, 
      {step:2, name:'Play Go-Quiz and Rank up GXp', imgSrc:vomaPlayGoQuizPhone, imgAltName:'Play Go-QUiz'}, 
      {step:3, name:'Join the tournament', imgSrc:vomaJoinTorunamentPhone, imgAltName:'Join tournament'}]
      const slides = slidesData.map(item => <Slide key={item.step} imgSrc={item.imgSrc} imgAltName={item.imgAltName} step={item.step} name={item.name} />)

      const dragEndHandler = (event, info) => {
        const offset = info.offset.x
        if (Math.abs(offset) > 50) {
          const direction = offset < 0 ? 1 : -1;
          if(direction < 1 && active === 0){
            setActive(1);
            return;
          }
          if(direction > 0 && active+1 >= slidesData.length){
            setActive(slidesData.length-2);
            return;
          }
          setActive(active => clamp(active + direction, 0, slides.length - 1));
          
        }
      }
      const onClickBtn = (type="") => {
        if(type === "next" && active+1 < slidesData.length){
            setActive(active+1);
            return;
        }
        if(type === "prev" && active > 0){
            setActive(active - 1);
            return;
        }
        setActive(active);
      }
    return(
        <>
        <Suspense fallback={<Loader />}>
            <BlankContainer>
            <div className='disp-flex wrap' style={{justifyContent:'stretch'}}>
                {/* <div> */}
                    <div className='disp-block align-center' style={{marginRight:'10px'}}>
                        <div className='disp-block'>
                            <img src={vomaLogo} className='voskool-logo head-img' alt="Voskool" />
                        </div>
                    </div>
                    <div>
                        <div className='disp-flex' style={{flexDirection:'column'}}>
                            <h4 className='app-text-sec-color font-big fw-700' style={{letterSpacing:'.6px', color:'#ccc'}}>VoMa Edutainment <br /> <span className='app-text-sec-color font-very-small fw-500' style={{fontStyle:'italic', color:'#aaa'}}>Think Better, Grow Richer!</span></h4> 
                        </div>
                    </div>
            </div>
        </BlankContainer>
            <div className='disp-block' >
                <img src={vomaFeatureImg} className='' alt="Voskool" style={{width:'100%', maxHeight:'75vh',}} />
            </div>
            {/* <IconPalletContainer>
                <IconPallete />
            </IconPalletContainer> */}
            <BlankContainer>
            <div className='disp-flex wrap' style={{justifyContent:'center'}}>
                <CustomButton iconClassName={'fas fa-download font-bigger'} numberDownloads={20}>
                    <a className='cont-btn' href="asset/release/voma.apk" download={'VoMa v1.0.6.apk'}>
                        Start Download Now
                        <div className='disp-block'>
                            <span style={{color:'#333'}} className='font-super-small'>For Android: <span className='font-very-super-small'>Version: 1.0.6</span></span>
                        </div>
                    </a>
                </CustomButton>
                <CustomPlayButton imgLogo={playStoreLogo} name="Google Play Store" />
            </div>
            <div className="disp-block align-center">
                <Note><span className='font-very-small'> To install, enable "Install from Unknown Sources" on your device</span></Note>
            </div>
            <div className='disp-flex wrap main-container'>
                <div className='flex-50'>
                    <div className='disp-block align-center' style={{paddingRight:'10px'}}>
                        <div className='disp-flex' style={{justifyContent:'center', alignItems:'end', border:'2px solid var(--appBodyColor)', padding:'10px'}}>
                            <div className='disp-block'>
                                <i className='fas fa-trophy font-bigger' style={{color:'#e5b80b'}}></i>
                            </div>  
                            <div className='disp-block'>
                                <span className='app-bg-color-text fw-700 font-super-small' style={{color:'#e5b80b'}}>x100 WINNERS</span>
                            </div>
                            <div className='disp-block' style={{paddingLeft:'10px'}}>
                                <i className='fas fa-money-bill-wave font-small' style={{color:'#e5b80b'}}></i>
                            </div>  
                        </div>          
                    </div>
                    <div className='disp-flex jc-stretch wrap align-center' style={{alignItems:'center', justifyContent:'center'}}>
                        <div className='disp-block header'>
                            <h2 className='app-bg-text-color font-bigger fw-700' style={{letterSpacing:'1px'}}>Join Tournament & Win Cash Prize</h2>
                        </div>
                    </div>
                    <div className='disp-block align-center'>
                        <span className='font-small app-bg-text-color fw-500' style={{lineHeight:1.7, color:'#ddd'}}>With <b>100 WINNERS</b> and the top prize at a whooping <b>N400,000</b> <br /> join "The Hidden Genius Mathematician" Go-Quiz Game Online Tournament <br /> to win the first Go-Quiz Championship award and cash prize</span>
                    </div>
                    <div className='disp-flex wrap' style={{marginTop:'20px', justifyContent:'end', alignItems:'center', alignContent:'center'}}>
                        <span className='fw-700 app-bg-text-color font-very-small'>Kano - Nigeria Edition</span>
                        <div style={{paddingLeft:'10px'}}>
                            <img src={nigerianFlagLogo} alt="nigeria" style={{width:'30px', height:'15px'}} />
                        </div>
                    </div>
                    <div className='disp-block align-right' style={{marginTop:'10px'}}>
                        <span className='fw-700 app-bg-text-color font-very-small'>For SS1 - 3 / Grade 9 - 11 / Jambite Students / University Students</span>
                    </div>
                    <div className='disp-block align-right' style={{marginTop:'10px'}}>
                        <span className='fw-600 app-bg-text-color font-very-small'>Age: 13 - 23</span>
                    </div>
                </div>
                <div className='flex-50 padd-cont'>
                    <div className='disp-block header'>
                        <h2 className='app-text-color font-super-big fw-700' style={{color:'#eee'}}>How to win?</h2>
                    </div>
                    
                    <div className="container" ref={constraintsRef}>
                        <div className='disp-flex wrap jc-sb'>
                                
                            <div className='disp-block'>
                            {active > 0 && (<div className='disp-block'><button className='br-circle btn padd-4px app-bg-color app-bg-text-color font-small hover-opacity' onClick={() => onClickBtn('prev')} style={{padding:'5px 10px'}}><i className='fas fa-arrow-left'></i></button> <div className='disp-block align-center'><span className='font-super-small fw-600' style={{color:'#aaa'}}>Prev</span></div></div>)}
                            </div>
                        
                        
                        
                        
                            <div className='disp-block'>
                            {active+1 < slidesData.length && (<div className='disp-block'><button className='br-circle btn padd-4px app-bg-color app-bg-text-color font-small hover-opacity' onClick={() => onClickBtn('next')} style={{padding:'5px 10px'}}><i className='fas fa-arrow-right'></i></button> <div className='disp-block align-center'><span className='font-super-small fw-600' style={{color:'#aaa'}}>Next</span></div></div>)}
                            </div>
                            
                            
                        </div>
                        <motion.div
                        className="swipper"
                        onDragEnd={dragEndHandler}
                        //dragConstraints={constraintsRef}
                        drag="x"
                        className="slider"
                        animate={{
                            x: -1 * active * width
                        }}
                        draggable={false}
                        >
                        {slides}
                        </motion.div>
                        <Dots count={slides.length} active={active} />
                    </div>
                    {/* <div style={{ height: 700 }} /> */}
                </div>
            </div>
            </BlankContainer>    
            <Footer />        
                {/* <img src={manMoneyImg} className='' alt="Man with the money" /> */}
        <SchoolAppStyle />
        </Suspense>
        </>
    )
}

const Voskool = ({state}) => {
    // const [user, setUser] = useState(state);

    // const [searchParam] = useSearchParams();

    // useEffect(() => {
    //     setUser(state);
    // }, [state]);

    // useEffect(() => {
    //     const local_storage = searchParam.get('act') ? {access:searchParam.get('act'), refresh:searchParam.get('rft')} : {access:'', refresh:''}
    //     if(local_storage.access && !localStorage.getItem('access')){
    //         localStorage.removeItem('access');
    //         localStorage.setItem('access', JSON.stringify(local_storage));
    //         window.location.reload();
    //     }
    // }, [searchParam])

    return(
        <>  
            <Routes>
                <Route path="/" element={<IndexComponent />} />
                {/* <Route path="/login" element={<LoginPage user={user.data} is_close={false} />} />
                {!user.isAuthenticated && (
                    <>
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/reset_password" element={<PasswordResetConfirmationPage />} />
                        <Route path="/activate/:uid/:token" element={<ActivateUserPage />} />
                        <Route path="/password/reset/:uid/:token" element={<PasswordResetPage />} />
                    </>
                ) }
                <Route path="/register" element={<CreateSchoolAccount user={user.data} />} /> */}
                <Route path="/terms_of_service" element={<TermsOfService />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            </Routes>
            <GlobalStyle />
        </>
    )
}

export default Voskool;