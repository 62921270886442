import React from "react";
import { OuterWrapper, Wrapper } from "./style";

const CustomButton = ({children, iconClassName, numberDownloads=20}) => {
    return(
        <OuterWrapper>
            <Wrapper className="font-med fw-500">
                <div className="disp-block" style={{marginRight:'10px'}}>
                    <span className={iconClassName}></span>
                </div>
                <div className="disp-block">
                    {children}
                </div>
            </Wrapper>
            <div className="disp-block cont-text align-center">
                <span className="font-very-small" style={{color:'#eee', fontStyle:'italic'}}>{numberDownloads} <span className="font-super-small">Downloads</span> </span> 
            </div>
        </OuterWrapper>
    )
}

export default CustomButton;